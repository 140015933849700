import {
  FormattedDateTime,
  Panel,
  SearchPagination,
  Table,
  usePagedQuery
} from '@elotech/components';
import { LABEL_PROCESSO } from 'labels';
import { useEffect } from 'react';
import { ProcessoUsuarioViewService } from 'service';

export const ProcessoUsuarioViewList = ({ processo }) => {
  const { values, pagination, loading, doPagedSearch, doSortChange, doSearch } =
    usePagedQuery({
      search: (_, pagination, sort) =>
        ProcessoUsuarioViewService.findAllByProcesso(
          processo.entidade?.id,
          processo.ano,
          processo.tipo?.id,
          processo.numero,
          pagination,
          sort
        ),
      onError: error => Promise.reject(error),
      disableQueryPagination: true
    });

  useEffect(() => {
    doSearch();
  }, []);

  return (
    <>
      <Panel
        isTable
        collapsed={true}
        expansible
        isForm={false}
        title={`Visualizações do ${LABEL_PROCESSO}`}
      >
        <Table
          fixed
          sortOnHeaderColumnClick={doSortChange}
          loading={loading}
          keyExtractor={p => p.id}
          values={values}
        >
          <Table.Column
            sortable
            name="data"
            header="Data"
            value={p => <FormattedDateTime value={p.data} />}
          />
          <Table.Column
            name="usuario.nome"
            sortable
            header="Nome"
            value={p => p.pessoa?.nome || p.usuario?.nome}
          />
          <Table.Column
            name="acessoExterno"
            sortable
            header="Acesso Externo"
            value={p => (p.pessoa?.id ? 'Sim' : 'Não')}
          />
        </Table>
        {pagination && pagination.totalPages > 0 && (
          <SearchPagination page={pagination} searchWithPage={doPagedSearch} />
        )}
      </Panel>
    </>
  );
};
