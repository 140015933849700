export enum MimeType {
  APPLICATION_CSV = 'application/csv',
  APPLICATION_MSWORD = 'application/msword',
  APPLICATION_JSON = 'application/json',
  APPLICATION_PDF = 'application/pdf',
  APPLICATION_APPLICATION = 'application/application',
  APPLICATION_VND = 'application/vnd',
  APPLICATION_XML = 'application/xml',
  APPLICATION_ZIP = 'application/zip',
  APLICATION_OCTET = 'aplication/octet',
  APLICATION_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  TEXT_PLAIN = 'text/plain',
  IMAGE_JPEG = 'image/jpeg',
  TEXT_HTML = 'text/html'
}
