import { FilterUtils } from '@elotech/components';
import { isNumber } from 'common/utils';
import { axios } from 'libs';

const resource = '/usuarios';
const defaultSort = {
  sort: 'nome,asc'
};

export const findAllAutocomplete = (
  search = '',
  tipoProcesso = null,
  pagination,
  sort = defaultSort
) => {
  const defaultFilter = 'ativo==true;';

  let filter = defaultFilter.concat(
    isNumber(search)
      ? `cpf==*${search}*`
      : FilterUtils.buildFilter(['nome'])(search)
  );

  if (tipoProcesso) {
    filter += ` and tipos.id==${tipoProcesso}`;
  }

  return axios.get(`${resource}/dto`, {
    params: {
      search: filter,
      sort: 'id,asc',
      ...pagination
    }
  });
};

export const findAll = (search, pagination, sort = defaultSort) => {
  return axios.get(resource, {
    params: {
      search,
      ...pagination,
      ...sort
    }
  });
};

export const findOne = id => {
  return axios.get(`${resource}/${id}`);
};

export const findUsuarioLogado = () => {
  return axios.get('/userinfo');
};

export const save = usuario => {
  return usuario.id
    ? axios.put(`${resource}/${usuario.id}`, usuario)
    : axios.post(resource, usuario);
};

export const findUserNameById = id => {
  return axios.get(`${resource}/nome/${id}`);
};

export const isUsuarioAdministrador = id => {
  return axios.get(`${resource}/${id}/isUsuarioAdministrador`);
};
